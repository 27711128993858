import {
  Badge,
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ApiFindAccountsOptions } from "@operations-hero/lib-api-client";
import { addDays, format } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { UseTableCellProps } from "react-table";
import { UserBadge } from "../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { PageData } from "../../components/pagination/PageData";
import { Pager } from "../../components/pagination/Pager";
import { AccountsData } from "../../services/api-internal/models/AccountData";
import { RootState } from "../../store";

export interface AccountListProps {
  filters: ApiFindAccountsOptions;
  updateFilters: (value: Partial<ApiFindAccountsOptions>) => void;
}
export const AccountList: FC<AccountListProps> = ({
  filters,
  updateFilters,
}: AccountListProps) => {
  const { accounts, total } = useSelector((state: RootState) => state.accounts);
  const { page, pageSize } = filters;
  const tableHeaderBg = useColorModeValue("blue.50", "transparent");
  const productBadgeColor = useColorModeValue("blue", "gray");

  const dateFormat = useBreakpointValue({
    base: "yyyy-MM-dd",
    sm: "yyyy-MM-dd",
    md: "yyyy-MMM-dd 'at' HH:mm",
  });

  const onPageChange = useCallback(
    (page: number) => {
      updateFilters({ ...filters, page });
    },
    [updateFilters, filters]
  );

  const isExpiring = useCallback((date: Date) => {
    const currentDate = new Date();
    const ninetyDaysFromNow = addDays(currentDate, 90).getTime();

    const targetDate = date.getTime();
    const currentDatetime = currentDate.getTime();

    return currentDatetime <= targetDate && targetDate <= ninetyDaysFromNow;
  }, []);

  const getSubscriptionBadgeColor = useCallback(
    (expirationDate: string) => {
      const date = new Date(expirationDate);
      if (isExpiring(date)) {
        return "yellow";
      }

      if (date.getTime() < new Date().getTime()) {
        return "red";
      }
      return "blue";
    },
    [isExpiring]
  );

  const columns = useMemo<DataTableColumn<AccountsData>[]>(
    () => [
      {
        Header: "Name",
        Cell: (data: UseTableCellProps<AccountsData>) => {
          return (
            <Text isTruncated fontWeight="semibold">
              {data.row.original.name}
            </Text>
          );
        },
      },
      {
        Header: "State",
        maxWidth: 150,
        Cell: (data: UseTableCellProps<AccountsData>) => {
          return (
            <Text isTruncated>{data.row.original.address.state || " - "}</Text>
          );
        },
      },
      {
        Header: "Owner",
        Cell: (data: UseTableCellProps<AccountsData>) => {
          return <UserBadge value={data.row.original.owner} />;
        },
      },
      {
        Header: "Created",
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Text>
            {format(new Date(data.row.original.created), dateFormat || "")}
          </Text>
        ),
      },
      {
        Header: "Products/Subscriptions",
        minWidth: 200,
        Cell: (data: UseTableCellProps<AccountsData>) => (
          <Wrap w="100%">
            {data.row.original.subscriptions.slice(0, 4).map((sub) => (
              <WrapItem
                key={`account:${data.row.original.id}::subscription:${sub.product.name}`}
              >
                <Badge
                  colorScheme={
                    !sub.product.isActive
                      ? "red"
                      : getSubscriptionBadgeColor(sub.expires)
                  }
                  borderRadius={8}
                  variant="subtle"
                >
                  {sub.product.name} - exp:
                  {new Date(sub.expires).toLocaleDateString()}
                  {sub.isTrial ? " (Trial)" : ""}
                </Badge>
              </WrapItem>
            ))}
            {data.row.original.subscriptions.length > 4 && (
              <WrapItem>
                <Badge colorScheme={productBadgeColor} borderRadius={8}>
                  {data.row.original.subscriptions.length - 4} more...
                </Badge>
              </WrapItem>
            )}
          </Wrap>
        ),
      },
    ],
    [dateFormat, getSubscriptionBadgeColor, productBadgeColor]
  );

  return (
    <Flex w="100%" gap={2} flexDir="column">
      <PageData total={total} currentPage={page} pageSize={pageSize} />
      <DataTable
        columns={columns}
        rest={{ w: "100%" }}
        data={accounts}
        tableHeaderProps={{ p: 2, bgColor: tableHeaderBg, borderRadius: 6 }}
      />

      <Flex flexWrap="wrap" justifyContent="space-between">
        <PageData total={total} currentPage={page} pageSize={pageSize} />
        <Pager
          currentPage={page || 1}
          totalPages={Math.ceil(total / (pageSize || 50))}
          onPageChange={onPageChange}
        />
      </Flex>
    </Flex>
  );
};
